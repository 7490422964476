const imagedata = 
  [ "https://source.unsplash.com/random/?landscape/",
  "https://source.unsplash.com/random/?cats/",
  "https://source.unsplash.com/random/?dogs/",
  "https://source.unsplash.com/random/?wedding/",
  "https://source.unsplash.com/random/?portrait/",
  "https://source.unsplash.com/random/?sunset/",
  "https://source.unsplash.com/random/?sunrise/",
  "https://source.unsplash.com/random/?landscape/",
  "https://source.unsplash.com/random/?cats/",
  "https://source.unsplash.com/random/?dogs/",
  "https://source.unsplash.com/random/?wedding/",
  "https://source.unsplash.com/random/?portrait/",
  "https://source.unsplash.com/random/?sunset/",
  "https://source.unsplash.com/random/?sunrise/",
  "https://source.unsplash.com/random/?landscape/",
  "https://source.unsplash.com/random/?cats/",
  "https://source.unsplash.com/random/?dogs/",
  "https://source.unsplash.com/random/?wedding/",
  "https://source.unsplash.com/random/?portrait/",
  "https://source.unsplash.com/random/?sunset/",
  "https://source.unsplash.com/random/?sunrise/",
  "https://source.unsplash.com/random/?landscape/",
  "https://source.unsplash.com/random/?cats/",
  "https://source.unsplash.com/random/?dogs/",
  "https://source.unsplash.com/random/?wedding/",
  "https://source.unsplash.com/random/?portrait/",
  "https://source.unsplash.com/random/?sunset/",
  "https://source.unsplash.com/random/?sunrise/",
  "https://source.unsplash.com/random/?landscape/",
  "https://source.unsplash.com/random/?cats/",
  "https://source.unsplash.com/random/?dogs/",
  "https://source.unsplash.com/random/?wedding/",
  "https://source.unsplash.com/random/?portrait/",
  "https://source.unsplash.com/random/?sunset/",
  "https://source.unsplash.com/random/?sunrise/",
  ]


export default imagedata;