import BackofficeNav from "./BackofficeNav";

export default function BOsettings(){
  return(
    <div className="boScreen">
      <BackofficeNav />
      <div className="boElement">
        <h1>Parametres</h1>

        
      </div>
    </div>
  )
}