const imagedata2 = 
  [ "https://source.unsplash.com/random/?city/",
  "https://source.unsplash.com/random/?architecture/",
  "https://source.unsplash.com/random/?fruits/",
  "https://source.unsplash.com/random/?fields/",
  "https://source.unsplash.com/random/?ocean/",
  "https://source.unsplash.com/random/?jet/",
  "https://source.unsplash.com/random/?sports/",
  "https://source.unsplash.com/random/?city/",
  "https://source.unsplash.com/random/?architecture/",
  "https://source.unsplash.com/random/?fruits/",
  "https://source.unsplash.com/random/?fields/",
  "https://source.unsplash.com/random/?ocean/",
  "https://source.unsplash.com/random/?jet/",
  "https://source.unsplash.com/random/?sports/",
  "https://source.unsplash.com/random/?city/",
  "https://source.unsplash.com/random/?architecture/",
  "https://source.unsplash.com/random/?fruits/",
  "https://source.unsplash.com/random/?fields/",
  "https://source.unsplash.com/random/?ocean/",
  "https://source.unsplash.com/random/?jet/",
  "https://source.unsplash.com/random/?sports/",
  "https://source.unsplash.com/random/?city/",
  "https://source.unsplash.com/random/?architecture/",
  "https://source.unsplash.com/random/?fruits/",
  "https://source.unsplash.com/random/?fields/",
  "https://source.unsplash.com/random/?ocean/",
  "https://source.unsplash.com/random/?jet/",
  "https://source.unsplash.com/random/?sports/",
  "https://source.unsplash.com/random/?city/",
  "https://source.unsplash.com/random/?architecture/",
  "https://source.unsplash.com/random/?fruits/",
  "https://source.unsplash.com/random/?fields/",
  "https://source.unsplash.com/random/?ocean/",
  "https://source.unsplash.com/random/?jet/",
  "https://source.unsplash.com/random/?sports/",
  ]

  export default imagedata2